import { contactSubmit } from './contact.js';
import { navToggle } from './header.js';
import { heroCarousel } from './home.js';
import { reviewsCaro, reviewsMobileCaro } from './reviews.js';
import { testimonialsCaro, testimonialsMobileCaro } from './testimonials.js';
import { locationsToggle } from './locations.js' ;
import { pillarsToggle} from './pillars.js';

if (document.getElementById('contact_form')) {
	contactSubmit();
}

navToggle.init();

if(document.querySelector('#home_hero_caro')) {
	heroCarousel.init();
}

if(document.querySelector('#reviews_caro')){
	reviewsCaro.init();
}

if(document.querySelector('#reviews_caro_mobile')){
	reviewsMobileCaro.init();
}

if(document.querySelector('#testimonials_caro')){
	testimonialsCaro.init();
}

if(document.querySelector('#testimonials_caro_mobile')){
	testimonialsMobileCaro.init();
}

if(document.querySelector('.heading_states')) {
	locationsToggle.init();
}

if(document.querySelector('.pillars_cards')) {
	pillarsToggle.init();
}