import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const testimonialsCaro = (function(){

	let my = {};

	my.init = function () {
		const caro = document.querySelector('#testimonials_caro');

		const options = {
			element: caro,
			intervalTiming: false,
			useChevrons: false,
			showDots: true,
		};
		
		window.testimonialsCaro = AutomatitCarousel(options);
	};

	return my;
})();

export const testimonialsMobileCaro = (function(){

	let my = {};

	my.init = function () {
		const mobileCaro = document.querySelector('#testimonials_caro_mobile');

		const options = {
			element: mobileCaro,
			intervalTiming: false,
			useChevrons: false,
			showDots: true,
		};
		
		window.testimonialsMobileCaro = AutomatitCarousel(options);
	};

	return my;
})();