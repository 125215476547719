export const pillarsToggle = (function(){

	let my = {};

	my.init = function () {
		const pillarCovers = document.querySelectorAll('.pillar_cover');
		const covers = document.querySelectorAll('.pillar_cover');

		const addHide = (covers) => {
			covers.forEach(cover => {
				cover.classList.remove('hide');
			});
		};

		pillarCovers.forEach(pillarCover => {
			pillarCover.addEventListener('click', e => {
				let clickedPillar = e.currentTarget;
				if(clickedPillar.classList.contains('hide')){
					clickedPillar.classList.remove('hide');
					addHide(covers);
				} else {
					addHide(covers);
					clickedPillar.classList.add('hide');
				}
			});
		});
	};

	return my;
})();