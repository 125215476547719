export const locationsToggle = (function(){

	let my = {};

	my.init = function () {
		const stateButtons = document.querySelectorAll('.heading_state');
		const stateWrapper = document.querySelectorAll('.state_wrapper');
        
		const removeActive = (array) => {
			array.forEach(arrayItem => {
				arrayItem.removeAttribute('active');
			});
		};

		stateButtons.forEach(stateButton => {
			stateButton.addEventListener('click', e => {
				let clickedState = e.currentTarget;
				removeActive(stateButtons);
				removeActive(stateWrapper);
				clickedState.toggleAttribute('active');
				stateWrapper.forEach(state => {
					if(clickedState.getAttribute('data-state') === state.getAttribute('data-state')) {
						state.toggleAttribute('active');
					}
				});
			});
		});
	};

	return my;
})();